var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"title-presentation"},[(_vm.evaluation)?_c('div',{staticClass:"evaluation-name-title"},[_vm._v(" "+_vm._s(_vm.evaluation.title)+" ")]):_vm._e()]),(
        (_vm.evaluatee_view && _vm.isFinishTestEvaluation) ||
        (_vm.evaluator_view && _vm.selected_user)
      )?_c('ViewEvaluationGrade',{staticClass:"grade-container",attrs:{"user_id":_vm.evaluator_view ? _vm.selected_user : _vm.user.id,"evaluation_id":_vm.evaluation_id}}):_vm._e()],1),_c('div',{staticClass:"d-flex"},[(
        !_vm.allows_crud && !_vm.evaluatee_view && !_vm.evaluator_view && _vm.fetch_completed
      )?_c('b-button',{staticClass:"noprint ml-auto mr-0",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.fullImpressionEvaluation}},[_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"printer"}}),_c('span',[_vm._v("Impresión completa de la evaluación")])],1)]):_vm._e()],1),(_vm.evaluator_view && _vm.evaluation)?_c('SelectedEvaluatee',{attrs:{"evaluation":_vm.evaluation,"actualTest":_vm.testFilter[_vm.tabIndex]},on:{"selected_user":_vm.slotEvaluateeSelected}}):_vm._e(),_c('div',{staticClass:"container-buttons-evaluation",class:{
      'btn-preview-evaluation': _vm.preview_evaluation == true,
      'btn-preview-evaluation-no-edit': !_vm.allows_crud,
      'btn-is-locked-activate': _vm.evaluation && _vm.evaluation.is_locked,
    }},[(
        _vm.allows_crud &&
        _vm.evaluation &&
        !_vm.evaluation.is_locked &&
        !_vm.preview_evaluation
          ? _vm.allows_crud
          : false
      )?_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`modal-edit-evaluation-${_vm.evaluation_id}`)}}},[_c('b-icon-pencil-square',{staticClass:"pencil-icon"}),_vm._v(" Editar "+_vm._s(_vm.$getVisibleNames("evaluations2.evaluation", false, "Evaluación"))+" ")],1):_vm._e(),_c('div',[(
          _vm.evaluation &&
          !_vm.evaluation.is_locked &&
          _vm.allows_crud &&
          _vm.user &&
          !_vm.user.groups.includes(6) &&
          !_vm.preview_evaluation
        )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
          `Bloquear  ${_vm.$getVisibleNames(
            'evaluations2.evaluation',
            false,
            'Evaluación'
          )}`
        ),expression:"\n          `Bloquear  ${$getVisibleNames(\n            'evaluations2.evaluation',\n            false,\n            'Evaluación'\n          )}`\n        ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"lock-btn p-0 mr-2 noprint",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.changeStateEvaluation()}}},[_c('b-icon-unlock-fill')],1):_vm._e(),(
          _vm.evaluation &&
          _vm.evaluation.is_locked &&
          _vm.allows_crud &&
          _vm.user &&
          !_vm.user.groups.includes(6) &&
          !_vm.preview_evaluation
        )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
          `Desbloquear  ${_vm.$getVisibleNames(
            'evaluations2.evaluation',
            false,
            'Evaluación'
          )}`
        ),expression:"\n          `Desbloquear  ${$getVisibleNames(\n            'evaluations2.evaluation',\n            false,\n            'Evaluación'\n          )}`\n        ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"lock-btn p-0 mr-2 noprint",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.changeStateEvaluation()}}},[_c('b-icon-lock-fill')],1):_vm._e(),(_vm.testFilter.length > 0 && !_vm.evaluatee_view && !_vm.evaluator_view)?_c('b-button',{staticClass:"noprint",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.previewEvaluation}},[_vm._v(" "+_vm._s(!_vm.preview_evaluation ? "Ver como Estudiante" : "Ver como Profesor")+" ")]):_vm._e()],1)],1),(_vm.evaluation)?_c('b-modal',{attrs:{"id":`modal-edit-evaluation-${_vm.evaluation_id}`,"title":`Editar ${_vm.$getVisibleNames(
      'evaluations2.evaluation',
      false,
      'Evaluación'
    )}`,"hide-footer":"","size":"xl"}},[_c('NewEvaluationForm',{attrs:{"Evaluation":_vm.evaluation,"evaluation_scoped_id":_vm.evaluation.scope,"Sections":_vm.sections,"Matters":_vm.matters,"show_step":_vm.evaluation &&
        (_vm.evaluation.matter_evaluation != null ||
          _vm.evaluation.egress_profile_matter_evaluation != null ||
          _vm.evaluation.scope == 1),"EgressProfileMatters":_vm.egress_profile_matters,"ClosedEvaluatee":_vm.existEvaluateeEvaluation},on:{"closeModal":function($event){return _vm.$bvModal.hide(`modal-edit-evaluation-${_vm.evaluation_id}`)}}})],1):_vm._e(),(_vm.matter_evaluation != null)?_c('HeaderOutputFormat',{class:{
      'd-none':
        !_vm.isfullImpressionEvaluation &&
        !_vm.isPrintingRubrica &&
        !_vm.isPrintingInstrumento &&
        !_vm.isformatPartialTest &&
        !_vm.isformatTransversalInformation &&
        !_vm.isformatTransversalExam &&
        !_vm.isformatCorrectAnswerPartial &&
        !_vm.isformatCorrectAnswerTransversal,
    },attrs:{"evaluation_id":_vm.evaluation_id,"time_output_format":_vm.matter_evaluation,"isformatCorrectAnswerPartial":_vm.isformatCorrectAnswerPartial,"isformatCorrectAnswerTransversal":_vm.isformatCorrectAnswerTransversal}}):_vm._e(),_c('HeaderEvaluation',{class:{
      'd-none':
        (_vm.isPrintingRubrica ||
          _vm.isPrintingInstrumento ||
          _vm.isfullImpressionEvaluation ||
          _vm.isformatPartialTest ||
          _vm.isformatTransversalInformation ||
          _vm.isformatTransversalExam ||
          _vm.isformatCorrectAnswerPartial ||
          _vm.isformatCorrectAnswerTransversal) &&
        _vm.matter_evaluation != null,
    },attrs:{"evaluation_id":_vm.evaluation_id,"allows_crud":_vm.allows_crud &&
      _vm.evaluation &&
      !_vm.evaluation.is_locked &&
      !_vm.preview_evaluation
        ? _vm.allows_crud
        : false,"evaluatee_view":_vm.evaluatee_view}}),_c('b-card',{staticClass:"container-instrument mt-4",class:{ 'print-hide': _vm.isfullImpressionEvaluation == true },attrs:{"no-body":""}},[_c('b-tabs',{staticClass:"pt-2",attrs:{"active-nav-item-class":"font-weight-bold","no-nav-style":_vm.isPrintingRubrica ||
        _vm.isPrintingInstrumento ||
        _vm.isformatPartialTest ||
        _vm.isformatTransversalInformation ||
        _vm.isformatTransversalExam ||
        _vm.isformatCorrectAnswerPartial ||
        _vm.isformatCorrectAnswerTransversal},scopedSlots:_vm._u([(
          _vm.allows_crud &&
          _vm.evaluation &&
          !_vm.preview_evaluation &&
          !_vm.evaluation.test_creation_is_locked
        )?{key:"tabs-start",fn:function(){return [_c('div',{staticClass:"container-add-instrument"},[_c('b-button',{staticClass:"link-span add-new-instrument ml-0",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`modal-created-instrument`)}}},[_vm._v(" + Agregar "+_vm._s(_vm.$getVisibleNames("evaluations2.test", false, "Instrumento"))+" ")])],1)]},proxy:true}:null,{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-muted"},[_vm._v(" No hay ningún instrumento creado."),_c('br'),_vm._v(" Para crear un nuevo instrumento seleccione el botón \"Agregar "+_vm._s(_vm.$getVisibleNames("evaluations2.test", false, "Instrumento"))+"\". ")])]},proxy:true}],null,true),model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((_vm.testFilter),function(test,index){return _c('b-tab',{key:test.id,scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{class:{
              'print-hide':
                _vm.isPrintingRubrica ||
                _vm.isPrintingInstrumento ||
                _vm.isformatPartialTest ||
                _vm.isformatTransversalInformation ||
                _vm.isformatTransversalExam ||
                _vm.isformatCorrectAnswerPartial ||
                _vm.isformatCorrectAnswerTransversal,
            }},[_c('span',[(_vm.tabIndex == index)?_c('b-icon-chevron-double-down',{staticClass:"noprint spinner-tab",staticStyle:{"width":"1rem","height":"1rem"},attrs:{"variant":"primary"}}):_vm._e(),_vm._v(" "+_vm._s(test.title)+" ")],1),(
                _vm.evaluator_view &&
                _vm.selected_user != null &&
                _vm.evaluateeTestScore(test.id) != null
              )?_c('span',[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                  'Puntaje asignado'
                ),expression:"\n                  'Puntaje asignado'\n                ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}]},[_vm._v("( "+_vm._s(_vm._f("Round")(_vm.evaluateeTestScore(test.id))))]),_vm._v(" / "+_vm._s(test.max_score)+" ) ")]):_vm._e()])]},proxy:true}],null,true)},[(
            _vm.openTabsIndex.includes(index) ||
            _vm.evaluator_view ||
            (!_vm.evaluator_view && !_vm.evaluatee_view)
          )?[_c('div',{staticClass:"text-left ml-2"},[(
                _vm.evaluation &&
                (_vm.evaluation.type == 1 ||
                  _vm.evaluation.type == 2 ||
                  _vm.evaluation.type == 3) &&
                !_vm.allows_crud &&
                _vm.fetch_completed &&
                !_vm.evaluatee_view &&
                !_vm.evaluator_view &&
                _vm.user &&
                !_vm.user.groups.includes(7)
              )?_c('b-dropdown',{staticClass:"mt-4 mr-2 noprint",attrs:{"split":"","variant":"primary","size":"sm"},on:{"click":_vm.formatWrittenTest},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"printer"}}),_c('span',[_vm._v("Formato de salida")])],1)]},proxy:true}],null,true)},[_c('b-dropdown-form',{staticStyle:{"width":"410px"}},[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"name":"check-score","switch":""},model:{value:(_vm.checked_rubric_partial),callback:function ($$v) {_vm.checked_rubric_partial=$$v},expression:"checked_rubric_partial"}},[_vm._v(" Ocultar "),_c('b',[_vm._v(" "+_vm._s(_vm.$getVisibleNames("evaluations2.rubric", false, "Pauta"))+" ")]),_vm._v(" al Imprimir ")]),_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"name":"check-score","switch":""},model:{value:(_vm.checked_score),callback:function ($$v) {_vm.checked_score=$$v},expression:"checked_score"}},[_vm._v(" Ocultar "),_c('b',[_vm._v("Puntaje")]),_vm._v(" de la Pregunta al Imprimir ")]),_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"name":"check-weightning","switch":""},model:{value:(_vm.checked_weightning),callback:function ($$v) {_vm.checked_weightning=$$v},expression:"checked_weightning"}},[_vm._v(" Ocultar "),_c('b',[_vm._v("Ponderación")]),_vm._v(" de la Pregunta al Imprimir ")])],1)],1):_vm._e(),(
                _vm.evaluation &&
                (_vm.evaluation.type == 1 ||
                  _vm.evaluation.type == 2 ||
                  _vm.evaluation.type == 3) &&
                _vm.showCorrectAnswer(test.evaluation_situation) &&
                !_vm.allows_crud &&
                _vm.fetch_completed &&
                !_vm.evaluatee_view &&
                !_vm.evaluator_view &&
                !_vm.preview_evaluation &&
                _vm.user &&
                !_vm.user.groups.includes(7)
              )?_c('b-button',{staticClass:"mt-4 mr-2 noprint",attrs:{"split":"","variant":"primary","size":"sm"},on:{"click":_vm.formatCorrectAnswerPartial}},[_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"printer"}}),_c('span',[_vm._v("Formato Claves y "+_vm._s(_vm.$getVisibleNames("evaluations2.rubric", false, "Pauta"))+" ")])],1)]):_vm._e(),(
                _vm.evaluation &&
                _vm.evaluation.type == 4 &&
                !_vm.allows_crud &&
                _vm.fetch_completed &&
                !_vm.evaluatee_view &&
                !_vm.evaluator_view &&
                _vm.user &&
                !_vm.user.groups.includes(7)
              )?_c('b-button',{staticClass:"mt-4 mr-2 noprint",attrs:{"split":"","variant":"primary","size":"sm"},on:{"click":_vm.formatTransversalExamInformation}},[_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"printer"}}),_c('span',[_vm._v("Formato de Información ")])],1)]):_vm._e(),(
                _vm.evaluation &&
                _vm.evaluation.type == 4 &&
                !_vm.allows_crud &&
                _vm.fetch_completed &&
                !_vm.evaluatee_view &&
                !_vm.evaluator_view &&
                _vm.user &&
                !_vm.user.groups.includes(7)
              )?_c('b-dropdown',{staticClass:"mt-4 mr-2 noprint",attrs:{"split":"","variant":"primary","size":"sm"},on:{"click":_vm.formatTransversalExam},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"printer"}}),_c('span',[_vm._v("Formato de salida")])],1)]},proxy:true}],null,true)},[_c('b-dropdown-form',{staticStyle:{"width":"410px"}},[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"name":"check-score","switch":""},model:{value:(_vm.checked_transversal_specification_table),callback:function ($$v) {_vm.checked_transversal_specification_table=$$v},expression:"checked_transversal_specification_table"}},[_vm._v(" Ocultar "),_c('b',[_vm._v(" Tabla de Especificaciones ")]),_vm._v(" al Imprimir ")]),_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"name":"check-score","switch":""},model:{value:(_vm.checked_rubric_transversal),callback:function ($$v) {_vm.checked_rubric_transversal=$$v},expression:"checked_rubric_transversal"}},[_vm._v(" Ocultar "),_c('b',[_vm._v(" "+_vm._s(_vm.$getVisibleNames("evaluations2.rubric", false, "Pauta"))+" ")]),_vm._v(" al Imprimir ")]),_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"name":"check-score","switch":""},model:{value:(_vm.checked_score),callback:function ($$v) {_vm.checked_score=$$v},expression:"checked_score"}},[_vm._v(" Ocultar "),_c('b',[_vm._v("Puntaje")]),_vm._v(" de la Pregunta al Imprimir ")]),_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"name":"check-weightning","switch":""},model:{value:(_vm.checked_weightning),callback:function ($$v) {_vm.checked_weightning=$$v},expression:"checked_weightning"}},[_vm._v(" Ocultar "),_c('b',[_vm._v("Ponderación")]),_vm._v(" de la Pregunta al Imprimir ")])],1)],1):_vm._e(),(
                _vm.evaluation &&
                _vm.evaluation.type == 4 &&
                _vm.showCorrectAnswer(test.evaluation_situation) &&
                !_vm.allows_crud &&
                _vm.fetch_completed &&
                !_vm.evaluatee_view &&
                !_vm.evaluator_view &&
                !_vm.preview_evaluation &&
                _vm.user &&
                !_vm.user.groups.includes(7)
              )?_c('b-button',{staticClass:"mt-4 mr-2 noprint",attrs:{"split":"","variant":"primary","size":"sm"},on:{"click":_vm.formatTransversalCorrectAnswer}},[_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"printer"}}),_c('span',[_vm._v("Claves de Respuesta ")])],1)]):_vm._e()],1),(
              !_vm.evaluator_view ||
              (_vm.evaluator_view &&
                (_vm.evaluator_check_section ||
                  _vm.evaluator_check_evaluation ||
                  _vm.evaluator_check_tests))
            )?_c('ContainerInstrument',{key:_vm.key_evaluation_criteria,staticClass:"pl-1 pr-1",attrs:{"container_instrument_id":'upper_container_instrument',"test":test,"key_evaluation_criteria":_vm.key_evaluation_criteria,"allows_crud":_vm.allows_crud,"evaluation_id":_vm.evaluation_id,"evaluatee_view":_vm.evaluatee_view,"evaluator_view":_vm.evaluator_view,"isPrintingRubrica":_vm.isPrintingRubrica,"isformatPartialTest":_vm.isformatPartialTest,"isformatTransversalInformation":_vm.isformatTransversalInformation,"isformatTransversalExam":_vm.isformatTransversalExam,"isPrintingInstrumento":_vm.isPrintingInstrumento,"preview_evaluation":_vm.preview_evaluation,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"user_id":_vm.selected_user,"evaluation_situations":_vm.evaluation_situations,"matter_evaluation":_vm.matter_evaluation,"checked_rubric_partial":_vm.checked_rubric_partial,"checked_rubric_transversal":_vm.checked_rubric_transversal,"checked_transversal_specification_table":_vm.checked_transversal_specification_table,"checked_score":_vm.checked_score,"checked_weightning":_vm.checked_weightning,"isformatCorrectAnswerPartial":_vm.isformatCorrectAnswerPartial,"isformatCorrectAnswerTransversal":_vm.isformatCorrectAnswerTransversal},on:{"change_preview_evaluation":_vm.previewEvaluation,"print_instrument":_vm.printInstrument,"print_rubric":_vm.printRubric,"previous_tabs":() => _vm.tabIndex--,"next_tabs":() => _vm.tabIndex++,"is_fetch_completed":_vm.isFetchCompleted}}):_vm._e()]:_vm._e()],2)}),1)],1),(!_vm.evaluator_view && !_vm.evaluatee_view)?_c('div',{class:{ 'd-none': !_vm.isfullImpressionEvaluation }},_vm._l((_vm.testFilter),function(test,index){return _c('div',{key:test.id},[_c('div',{class:{ 'page-break': index != 0 }},[_c('h3',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(test.title)+" ")]),(
            !_vm.evaluator_view ||
            (_vm.evaluator_view &&
              (_vm.evaluator_check_section ||
                _vm.evaluator_check_evaluation ||
                _vm.evaluator_check_tests))
          )?_c('ContainerInstrument',{key:_vm.key_evaluation_criteria,attrs:{"container_instrument_id":'down_container_instrument',"test":test,"key_evaluation_criteria":_vm.key_evaluation_criteria,"allows_crud":_vm.allows_crud,"evaluation_id":_vm.evaluation_id,"evaluatee_view":_vm.evaluatee_view,"evaluator_view":_vm.evaluator_view,"isPrintingRubrica":_vm.isPrintingRubrica,"isformatPartialTest":_vm.isformatPartialTest,"isformatTransversalInformation":_vm.isformatTransversalInformation,"isformatTransversalExam":_vm.isformatTransversalExam,"isPrintingInstrumento":_vm.isPrintingInstrumento,"preview_evaluation":_vm.preview_evaluation,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"user_id":_vm.selected_user,"requests_within_the_component":false,"evaluation_situations":_vm.evaluation_situations,"matter_evaluation":_vm.matter_evaluation,"checked_rubric_partial":_vm.checked_rubric_partial,"checked_rubric_transversal":_vm.checked_rubric_transversal,"checked_transversal_specification_table":_vm.checked_transversal_specification_table,"checked_score":_vm.checked_score,"checked_weightning":_vm.checked_weightning,"isformatCorrectAnswerPartial":_vm.isformatCorrectAnswerPartial,"isformatCorrectAnswerTransversal":_vm.isformatCorrectAnswerTransversal},on:{"change_preview_evaluation":_vm.previewEvaluation,"print_instrument":_vm.printInstrument,"print_rubric":_vm.printRubric,"previous_tabs":() => _vm.tabIndex--,"next_tabs":() => _vm.tabIndex++}}):_vm._e()],1)])}),0):_vm._e(),_c('b-modal',{attrs:{"id":`modal-created-instrument`,"title":`Crear ${_vm.$getVisibleNames(
      'evaluations2.test',
      false,
      'Instrumento'
    )}`,"hide-footer":"","size":"xl"},on:{"hide":_vm.confirmClose}},[_c('NewInstrumentForm',{attrs:{"evaluation_id":_vm.evaluation_id,"show_step":_vm.evaluation &&
        (_vm.evaluation.matter_evaluation != null ||
          _vm.evaluation.egress_profile_matter_evaluation != null),"Section":_vm.section},on:{"change_evaluation_form":() => (_vm.change_evaluation_form = true),"closeModal":_vm.createdInstrument}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }